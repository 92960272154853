








import Vue from "vue";
import { Component } from "vue-property-decorator";
import { BSkeletonImg, BSkeleton, BSkeletonWrapper } from "bootstrap-vue";

@Component({
  name: "AvSkeletonMediaTabs",
  components: {
    BSkeletonImg,
    BSkeleton,
    BSkeletonWrapper,
  },
})
export default class AvSkeletonMediaTabs extends Vue {
  index: number = 0;

  classParaMobile(index: number): string {
    return index <= 2 ? "tw-flex" : "hidden";
  }
}
