<template>
  <av-card>
    <div class="table__skeleton-row">
      <b-skeleton height="30px"></b-skeleton>
    </div>
    <div class="tw-flex tw-space-x-5 table__skeleton-row">
      <b-skeleton width="20%" height="30px"></b-skeleton>
      <b-skeleton class="tw-flex-grow" height="30px"></b-skeleton>
    </div>
    <div class="tw-flex tw-space-x-10 table__skeleton-row">
      <div class="tw-flex tw-space-x-2 tw-flex-grow">
        <b-skeleton width="100%" height="35px" v-for="i in 7" :key="i"></b-skeleton>
      </div>
    </div>
  </av-card>
</template>

<script>
import { BSkeleton } from "bootstrap-vue";
import AvCard from "@/components/av-card/AvCard.vue";
import { Component, Vue } from "vue-property-decorator";

@Component( {
  name: "AvSkeletonTable",
  components: {
    BSkeleton,
    AvCard,
  }
} )
export default class AvSkeletonTable extends Vue {};
</script>

<style lang="scss" scoped>
.table__skeleton-grid {
  border-radius: 0;
  border: 1px solid #ddd;
  border-top: 0;
  max-height: 75vh;
}

.table__header-background {
  background-color: #f8f8f8;
}

.table__skeleton-row {
  border-top: 1px solid #ddd;
  padding: 0 1rem;
  padding-top: 1rem;
}
</style>
