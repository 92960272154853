

















import { BRow, BCol, BSkeleton, BSkeletonWrapper } from "bootstrap-vue";

import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "AvSkeletonChartPie",
  components: {
    BRow,
    BCol,
    BSkeleton,
    BSkeletonWrapper,
  },
})
export default class AvSkeletonChartPie extends Vue {
  @Prop({ default: "300px" }) height!: string;
}
