








import { AvSkeletonCard } from "@/components/av-skeleton";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component( {
  name: "AvSkeletonBoard",
  components: {
    AvSkeletonCard,
  }
} )
export default class AvSkeletonBoard extends Vue {
  @Prop( { required: true } ) quantity!: number;
};
