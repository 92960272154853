







































































































































































import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import { Ref, ref } from "@vue/composition-api";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import formValidation from "@core/comp-functions/forms/form-validation";
import { AvSkeletonInput } from "@/components/av-skeleton";
import { AvButton } from "@/components";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// Services
import { useRegion } from "@/services";

// Interfaces
import { IRegionCreate } from "../interfaces/IRegion";
import {
  IRegion,
  IRegionGet,
  IResponseGetRegion,
} from "@core/services/interfaces/covarege-area/region/IRegionService";

@Component({
  name: "RegionHandler",
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,
    AvButton,
    AvSkeletonInput,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isRegionSidebarActive",
    event: "update:is-region-handler-sidebar-active",
  },
})
export default class RegionHandler extends Vue {
  @Prop({ required: true, default: false })
  isRegionSidebarActive!: Ref<boolean>;
  @Prop({ required: true, default: [] }) statusOptions!: Array<{
    label: string;
    value: boolean;
  }>;
  @Prop({ required: true }) region!: Ref<IRegionCreate>;
  @Prop({ required: true, default: () => {} }) clearRegionData!: () => void;

  // Data
  regionLocal: Ref<IRegion> = ref({} as IRegion);
  loading = false;
  saving = false;
  validatingCode = false;
  validCode = false;

  @Watch("region")
  resetRegionLocal() {
    this.regionLocal.value = {} as IRegion;
  }

  formValidation = formValidation(this.resetRegionLocal, this.clearRegionData);
  clearFormData = ref(this.formValidation.clearForm);

  // Computeds
  get isSidebarActive(): boolean {
    return this.isRegionSidebarActive.value;
  }

  get isValidCode(): boolean | null {
    if (typeof this.regionLocal.value.codigo == "undefined") {
      return null;
    }

    if (!this.regionLocal.value.codigo) return null;

    return this.regionLocal.value.codigo >= 0 ? this.validCode : null;
  }

  // Watchs
  //--------------------------------------------------------
  // * Limpar formulário se a barra lateral estiver fechada
  //--------------------------------------------------------
  @Watch("isSidebarActive")
  openingModal(val: boolean) {
    if (!val) {
      setTimeout(() => {
        this.clearRegionData();
        this.resetRegionLocal();
      }, 350);
    }

    if (this.isRegionSidebarActive.value && this.region.value.id) {
      this.loading = true;
      useRegion
        .requestGet(this.region.value.id)
        .then((response: IResponseGetRegion) => {
          const data: IRegionGet = response.data.Data;

          this.regionLocal.value = {
            id: data.Id,
            nome: data.Nome,
            codigo: data.Codigo,
            status: data.Status,
            autor: data.Autor,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  // Functions
  /**
   * Abrir ou fechar o sidebar de edição/criação de região operacional
   */
  toggleSidebarRegionHandler = (val: boolean): void => {
    this.$emit("updateIsRegionSidebarActive", val);
  };

  /**
   * Mapeando o valor do select status de um objecto <{label, value}> para apenas value boolean
   */
  reduceValueStatus = (val: { label: string; value: boolean }): boolean => {
    return val.value;
  };

  checkStateCode(validationContext: any): boolean | null {
    if (this.validatingCode) {
      return null;
    }

    const formValidation =
      this.formValidation.getValidationState(validationContext);

    if (formValidation === null && this.isValidCode === null) {
      return null;
    } else if (this.isValidCode === null) {
      return formValidation;
    } else {
      return this.isValidCode;
    }
  }

  codeExist(code: number) {
    this.validCode = true;
    useRegion
      .requestValidCode(code, this.regionLocal.value.id ? true : false)
      .then((valid) => {
        if (typeof valid == "boolean") this.validCode = valid;
        else this.validCode = false;

        if (!valid) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Esse Código já esta em uso",
              icon: "AlertTriangleIcon",
              variant: "warning",
            },
          });
        }
      })
      .catch(() => {
        this.validCode = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Não foi possível válidar esse Código!",
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      })
      .finally(() => {
        this.validatingCode = false;
      });
  }

  async onSubmit() {
    this.saving = true;
    if (this.regionLocal.value.id) {
      useRegion
        .requestUpdate(this.regionLocal.value)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Região atualizada com sucesso!",
              icon: "CheckIcon",
              variant: "success",
            },
          });

          this.$emit("updateIsRegionSidebarActive", false);
          this.$emit("refreshData");
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erro ao atualizar região!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    } else {
      useRegion
        .requestCreate(this.regionLocal.value)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Região inserida com sucesso!",
              icon: "CheckIcon",
              variant: "success",
            },
          });

          this.$emit("updateIsRegionSidebarActive", false);
          this.$emit("refreshData");
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erro ao inserir região!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    }
  }
}
