var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-region-handler","sidebar-class":"sidebar-lg","bg-variant":"white","visible":_vm.isRegionSidebarActive.value,"shadow":"","backdrop":"","no-header":"","right":""},on:{"change":_vm.toggleSidebarRegionHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.region.value.id ? "Editar" : "Cadastrar")+" Região Operacional ")]),_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)]),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.formValidation.resetForm($event)}}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"name"}},[_c('template',{slot:"label"},[_vm._v(" Nome "),_c('span',{staticClass:"text-danger"},[_vm._v(" * ")])]),(_vm.loading)?_c('AvSkeletonInput'):_vm._e(),(!_vm.loading)?_c('b-form-input',{attrs:{"id":"name","autofocus":"","state":_vm.formValidation.getValidationState(validationContext),"trim":"","disabled":_vm.saving},model:{value:(_vm.regionLocal.value.nome),callback:function ($$v) {_vm.$set(_vm.regionLocal.value, "nome", $$v)},expression:"regionLocal.value.nome"}}):_vm._e(),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],2)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Código","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"code"}},[_c('template',{slot:"label"},[_vm._v(" Código "),_c('span',{staticClass:"text-danger"},[_vm._v(" * ")])]),(_vm.loading)?_c('AvSkeletonInput'):_vm._e(),(!_vm.loading)?_c('b-form-input',{class:{
                  'is-loading': _vm.validatingCode,
                },attrs:{"id":"code","type":"number","autofocus":"","state":_vm.checkStateCode(validationContext),"trim":"","disabled":_vm.validatingCode || _vm.saving},on:{"change":_vm.codeExist},model:{value:(_vm.regionLocal.value.codigo),callback:function ($$v) {_vm.$set(_vm.regionLocal.value, "codigo", $$v)},expression:"regionLocal.value.codigo"}}):_vm._e(),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],2)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Status","label-for":"Status","state":_vm.formValidation.getValidationState(validationContext)}},[_c('template',{slot:"label"},[_vm._v(" Status "),_c('span',{staticClass:"text-danger"},[_vm._v(" * ")])]),(_vm.loading)?_c('AvSkeletonInput'):_vm._e(),(!_vm.loading)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"reduce":_vm.reduceValueStatus,"label":"label","input-id":"status","disabled":_vm.saving},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
                var label = ref.label;
return [_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.regionLocal.value.status),callback:function ($$v) {_vm.$set(_vm.regionLocal.value, "status", $$v)},expression:"regionLocal.value.status"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":_vm.formValidation.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],2)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('av-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","loading":_vm.saving,"disabled":_vm.saving}},[_vm._v(" Salvar ")]),_c('av-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary","disabled":_vm.saving},on:{"click":hide}},[_vm._v(" Cancelar ")])],1)],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }