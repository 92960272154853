import { render, staticRenderFns } from "./AvSkeletonChartPie.vue?vue&type=template&id=289921e8&scoped=true"
import script from "./AvSkeletonChartPie.vue?vue&type=script&lang=ts"
export * from "./AvSkeletonChartPie.vue?vue&type=script&lang=ts"
import style0 from "./AvSkeletonChartPie.vue?vue&type=style&index=0&id=289921e8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "289921e8",
  null
  
)

export default component.exports